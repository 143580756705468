<template>
  <div>
    <!-- 短信登录 -->
    <el-form ref="thirdForm"  :model="formData" :rules="rules">
      <el-form-item prop="account">
        <div class="dxdl">
          <el-input class="dxdl-num-left" value="+86+"></el-input>
          <el-input
            class="dxdl-num-right"
            v-model="formData.account"
            placeholder="手机号码"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item  prop="password">
        <el-input
          type="password"
          v-model="formData.password"
          placeholder="新密码"
        ></el-input>
      </el-form-item>
      <el-form-item  prop="quepassword">
        <el-input
          type="password"
          v-model="formData.quepassword"
          placeholder="确认密码"
        ></el-input>
      </el-form-item>
      <Vcode :show="isShow" @success="success" @close="close" />
      <el-form-item prop="code">
        <div class="dxdl">
          <el-input
            class="dxdl-code-left"
            v-model="formData.code"
            placeholder="请输入验证码"
          ></el-input>
          <el-button  class="dxdl-code-right"  @click="isShow=true" :disabled="!this.codeFlag || !this.formData.accountBoole">{{codeBtnText}}</el-button>
        </div>
      </el-form-item>
    </el-form>

  </div>
</template>
<script>
import Vcode from 'vue-puzzle-vcode'
import { captchaCode, getSitepassword } from '../../network/api'
var _this
export default {
  components: {
    Vcode
  },
  props: {
    numOrSms: { default: true }, // 账号input为true,短信input为false
    showRegisterPwd: { default: false } // 注册页短信栏需要显示输入密码
  },
  data () {
    // 密码验证自定义
    var validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'))
      } else {
        if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(value)) {
          callback(new Error('由数字和字母组成,长度在6到16位之间'))
        }
        callback()
      }
    }

    return {
      isShow: false, // 验证码模态框是否出现
      formData: {
        account: '',
        password: '',
        quepassword: '',
        imgCaptcha: '',
        code: '',
        accountBoole: null,
        passwordBoole: null,
        codeBoole: null
      },
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      imgCode: '',
      obj: '',
      dialogVisible: false,
      codeFlag: true,
      timer: null,
      codeBtnText: '获取验证码',
      vid: '',
      rules: {
        account: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          {
            pattern: /(^1[0-9]{10}$)/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }
        ],
        password: [{ validator: validatePass, trigger: 'blur' }],
        code: [{ required: true, message: '请输入手机验证码', trigger: 'blur' }],
        imgCaptcha: [
          { required: true, message: '请输入四位数验证码 ', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getvid()
  },
  methods: {
    // 用户通过了验证
    success (msg) {
      this.isShow = false // 通过验证后，需要手动隐藏模态框
      this.getCode()
    },
    // 用户点击遮罩层，应该关闭模态框
    close () {
      this.isShow = false
    },
    onSubmit (imgCaptcha) {
      // console.log(imgCaptcha.toString())
      // 发送提交
      captchaCode(imgCaptcha.toString(), this.$appkey).then((res) => {
        // console.log(res)
        this.formData.imgCaptcha = ''
        if (res.code === '') {
          this.$notify.warning({
            title: '验证码不能为空',
            message: res.msg
          })
        } else if (res.code === 1) { // 验证码正确
          this.$notify({
            message: res.msg,
            type: 'success'
          })
          this.getCode()
        } else if (res.code === 0) { // 请重新输入
          this.$notify.error({
            title: '验证码输入有误',
            message: res.msg
          })
        }
      })
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    getvid () {},
    verfiy () {
      const a = 'resetpwd'
      getSitepassword(a, this.$appkey).then(res => {
        console.log(res)
        // this.vid = res.data.vaptcha_key
        window.vaptcha({
          vid: res.data.vaptcha_key,
          type: 'invisible'
        })
          .then(function (vaptchaObj) {
            _this.obj = vaptchaObj // 将 VAPTCHA 验证实例保存到局部变量中
            // 验证码加载完成后将事件绑定到按钮
            // 调用 validate()方法的伪代码，将该方法的调用绑定在'click'事件上，实际开发中需要更改为合适的调用方式
            vaptchaObj.listen('pass', function () {
              // 验证成功进行后续操作
              _this.getCode()
            })
          })
      })
    },
    // 获取验证码:需要先向父组件发送一个事件 只能由父组件来完成
    getCode () {
      let time = 60
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (time > 0) {
            this.codeFlag = false
            this.codeBtnText = time + 's后重新发送'
            time--
          } else {
            clearInterval(this.timer)
            this.codeFlag = true
            this.codeBtnText = '获取验证码'
            this.timer = null
          }
        }, 1000)
      }
      this.$emit('get-code')
    }
  },
  mounted () {
    _this = this
    this.verfiy()
  },
  watch: {
    // 监听表单数据并传递给父组件
    formData: {
      handler (newData, oldData) {
        // 判断验证真假
        this.$refs.thirdForm.validateField('account', (valid) => {
          if (valid) {
            this.formData.accountBoole = false
          } else {
            this.formData.accountBoole = true
          }
        })
        this.$refs.thirdForm.validateField('password', (valid) => {
          if (valid) {
            this.formData.passwordBoole = false
          } else {
            this.formData.passwordBoole = true
          }
        })
        this.$refs.thirdForm.validateField('code', (valid) => {
          if (valid) {
            this.formData.codeBoole = false
          } else {
            this.formData.codeBoole = true
          }
        })
        // 向父组件发送事件传递数据
        this.$emit('form-data', newData)
      },
      deep: true
    }
  }

}

</script>

<style>
.dxdl {
  display: flex;
  flex-direction: row;
}
.dxdl-num-left {
  margin-right: -1px;
  width: 80px;
}
.dxdl-num-left input.el-input__inner,
.dxdl-code-left input.el-input__inner {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.dxdl-num-right input.el-input__inner,
.dxdl-code-right.el-button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.dxdl-code-right.el-button {
  border-left: none;
}
/* --- */
/* --- */
</style>
